exports.NOT_FOUND = "NOT_FOUND";

// Application process
exports.APPLICATION_PROCESS_ACTIVE = "APPLICATION_PROCESS_ACTIVE";
exports.APPLICATION_PROCESS_CLOSED = "APPLICATION_PROCESS_CLOSED";

// Application
exports.APPLICATION_ACTIVE = "APPLICATION_ACTIVE";
exports.APPLICATION_DUPLICATED = "APPLICATION_DUPLICATED";

// Candidate
exports.CANDIDATE_EMAIL_DUPLICATED = "CANDIDATE_EMAIL_DUPLICATED";
exports.CANDIDATE_REFER_EMAIL_DUPLICATED = "CANDIDATE_REFER_EMAIL_DUPLICATED";

// Roles and Skills
exports.DUPLICATED = "DUPLICATED";
