import { useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import { toast } from "react-toastify";
import {
  faDownload,
  faTrashAlt,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { saveAs } from "file-saver";
import { CandidateApi } from "micoach-api";

import CheckboxListModal from "Components/Common/CheckboxListModal";
import ConfirmModal from "Components/ConfirmModal";
import FormattedDate from "Components/FormattedDate";
import UploadDocumentModal from "Components/UploadDocumentModal";
import {
  formattedSize,
  getFormattedLocalDate,
  getMimeTypeIcon,
} from "utils.js";

import "Components/styles/Documents.css";

const Documents = (props) => {
  const { candidate, isEditable } = props;

  const [documents, setDocuments] = useState(candidate.documents ?? []);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [showUploadDocumentModal, setShowUploadDocumentModal] = useState(false);
  const [showDeleteDocumentModal, setShowDeleteDocumentModal] = useState(false);
  const [showDownloadDocumentsModal, setShowDownloadDocumentsModal] =
    useState(false);
  const [downloadingDocuments, setDownloadingDocuments] = useState(false);
  const [deletingDocument, setDeletingDocument] = useState(false);

  const refreshDocuments = (documents) => {
    props.onUpdated?.();
    setDocuments(documents);
  };

  const sortByUploadDate = (documents) => {
    if (!documents) {
      return [];
    }

    return documents.sort((a, b) => {
      const dateA = new Date(a.updatedAt);
      const dateB = new Date(b.updatedAt);

      if (dateA < dateB) {
        return 1;
      } else {
        return -1;
      }
    });
  };

  const handleDownloadDocument = async (document) => {
    try {
      const { data } = await CandidateApi.getDownloadDocumentUrl(
        candidate?._id,
        document?._id
      );

      if (!data?.url) {
        return;
      }

      window.open(data.url, "_blank");
    } catch (error) {
      toast.error("Error! Unable to download the document, try again.");
    }
  };

  const handleConfirmDownloadDocuments = async (documentIds) => {
    setDownloadingDocuments(true);
    try {
      const { data } = await CandidateApi.getExportZip(
        candidate?._id,
        documentIds,
        { responseType: "arraybuffer" }
      );
      saveAs(
        new Blob([data]),
        `${candidate?.name}_${getFormattedLocalDate(
          new Date(),
          "YYYY-MM-DD"
        )}.zip`
      );
      setShowDownloadDocumentsModal(false);
    } catch (error) {
      toast.error("Error! Unable to download the documents, try again.");
    } finally {
      setDownloadingDocuments(false);
    }
  };

  const handleDeleteDocument = (document) => {
    setSelectedDocument(document);
    setShowDeleteDocumentModal(true);
  };

  const handleConfirmDeleteDocument = async () => {
    setDeletingDocument(true);
    try {
      const { data } = await CandidateApi.deleteDocument(
        candidate?._id,
        selectedDocument._id
      );
      refreshDocuments(data);
      setShowDeleteDocumentModal(false);
      props.onUpdated?.();
      toast.success("Success! The document has been deleted.");
    } catch (error) {
      toast.error(error.response.data?.message ?? "Error");
    } finally {
      setDeletingDocument(false);
    }
  };

  return (
    <>
      <UploadDocumentModal
        show={showUploadDocumentModal}
        candidateId={candidate?._id}
        isMultiple={true}
        onClose={() => setShowUploadDocumentModal(false)}
        onSaved={refreshDocuments}
      />

      <ConfirmModal
        show={showDeleteDocumentModal}
        title="Delete document"
        confirmButtonText="Yes, delete"
        onClose={() => setShowDeleteDocumentModal(false)}
        onConfirm={handleConfirmDeleteDocument}
        isLoading={deletingDocument}
      >
        <div>
          Are you sure you want to delete the document{" "}
          <strong>{selectedDocument?.fileName}</strong>?
          <div className="mt-3"> This action cannot be undone.</div>
        </div>
      </ConfirmModal>

      <CheckboxListModal
        show={showDownloadDocumentsModal}
        disabled={downloadingDocuments}
        title="Download documents"
        subtitle="Select all the documents you want to download"
        options={documents}
        defaultValue={documents}
        id="_id"
        label="fileName"
        value="_id"
        confirmButtonText="Download"
        onClose={() => setShowDownloadDocumentsModal(false)}
        onConfirm={handleConfirmDownloadDocuments}
      />

      <div className="Documents">
        <h2>Documents</h2>

        <ListGroup variant="flush">
          {sortByUploadDate(documents).map((document) => {
            return (
              <ListGroup.Item key={document._id} title={document.fileName}>
                <Row>
                  <Col xs={10} className="pe-0">
                    <FontAwesomeIcon
                      icon={getMimeTypeIcon(document.contentType)}
                      size="lg"
                    />
                    <Button
                      className="DocumentName link"
                      title={document.fileName}
                      variant="link"
                      onClick={() => {
                        handleDownloadDocument(document);
                      }}
                    >
                      {document.fileName}
                    </Button>
                    <span className="DocumentSize me-2">
                      | {formattedSize(document.size)}
                    </span>
                  </Col>
                  <Col xs={2} className="text-end">
                    {isEditable && (
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        className="Delete"
                        onClick={() => handleDeleteDocument(document)}
                      />
                    )}
                  </Col>
                </Row>

                <div className="DocumentDate">
                  <FormattedDate>{document.updatedAt}</FormattedDate>
                </div>
              </ListGroup.Item>
            );
          })}
        </ListGroup>

        {documents.length > 0 && (
          <Button
            variant="link"
            className="w-100 mt-4 fs-14"
            onClick={() => setShowDownloadDocumentsModal(true)}
          >
            <FontAwesomeIcon icon={faDownload} className="me-2" />
            Download documents
          </Button>
        )}

        {isEditable && (
          <Button
            variant="secondary"
            className="w-100 mt-2"
            onClick={() => setShowUploadDocumentModal(true)}
          >
            <FontAwesomeIcon icon={faUpload} className="me-2" />
            Upload document
          </Button>
        )}
      </div>
    </>
  );
};

Documents.propTypes = {
  candidate: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    documents: PropTypes.arrayOf(PropTypes.object).isRequired,
    applicationProcess: PropTypes.shape({
      position: PropTypes.shape({
        title: PropTypes.string,
      }),
    }),
  }).isRequired,
  isEditable: PropTypes.bool,
  onUpdated: PropTypes.func,
};

Documents.defaultProps = {
  isEditable: false,
};

export default Documents;
