import { useEffect } from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import Address from "Components/Address";
import PhoneNumberInput from "Components/PhoneNumberInput";
import StepButton from "Components/ProfileBuilder/StepButton";
import { useUpdateProfile } from "Hooks/useUsers";

import styles from "Components/ProfileBuilder/styles/ContactInfoStep.module.css";

const ContactInfoStep = (props) => {
  const { userEmail = "", onSaved } = props;

  const {
    register,
    formState: { errors },
    control,
    watch,
    setValue,
    handleSubmit,
  } = useForm({
    mode: "onChange",
  });
  const formProps = { register, errors, control, watch, setValue };

  const { updateProfile, isUpdating, isSuccess, status, errorMessage } =
    useUpdateProfile();

  useEffect(() => {
    if (isSuccess) {
      onSaved?.();
    } else {
      if (status && errorMessage) {
        const toastType = status >= 500 ? "error" : "info";
        toast[toastType](errorMessage);
      }
    }
  }, [isSuccess, onSaved, status, errorMessage]);

  const onSubmit = async (formValues) => {
    const updatedProfile = {
      phone: formValues.phone ?? "",
      callingCode: "",
      address: {
        city: formValues.city ?? "",
        state: formValues.state?.value,
        country: formValues.country?.value,
      },
    };

    if (formValues.phone) {
      updatedProfile.callingCode = formValues.callingCode;
    }

    await updateProfile(updatedProfile);
  };

  return (
    <>
      <span className={styles.EmailLabel}>Your Personal Email</span>
      <span className={styles.Email}>{userEmail}</span>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Form.Group className="form-group">
          <PhoneNumberInput formProps={formProps} disabled={isUpdating} />
        </Form.Group>

        <Address
          requireContry
          requireCity
          profileBuilderFormat
          isEditing={true}
          formProps={formProps}
          disabled={isUpdating}
        />
        <StepButton label="Continue" type="submit" disabled={isUpdating} />
      </Form>
    </>
  );
};

ContactInfoStep.propTypes = {
  userEmail: PropTypes.string,
  onSaved: PropTypes.func,
};

export default ContactInfoStep;
