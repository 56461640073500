import { useContext, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  App,
  getAccountItems,
  getActionItems,
  getMainItems,
  getProfileItems,
  Header as CommonHeader,
} from "@itj-micoach/micoach-common";
import { useMediaQuery } from "usehooks-ts";

import ActiveLink from "Components/Common/ActiveLink";
import LoginAndRegisterButtons from "Components/Common/LoginAndRegisterButtons";
import UnreadTasksCounter from "Components/Task/UnreadTasksCounter";
import { UserProfileContext } from "Context/UserProfileProvider";

const Header = (props) => {
  const { userRoles, isOffCanvas = false } = props;

  const [showDropdown, setShowDropdown] = useState(false);

  const isDesktop = useMediaQuery("(max-width: 1600px)");
  const { keycloak } = useKeycloak();
  const { profileCompletion } = useContext(UserProfileContext);
  const history = useHistory();

  const handleClickLogout = async () => {
    await keycloak.logout({
      redirectUri: `${window.location.origin}/logout`,
    });
  };

  const handleClickChangePassword = async () => {
    await keycloak.login({ action: "UPDATE_PASSWORD" });
  };

  const isLogged = userRoles?.length > 0;

  let offCanvas;
  if (isOffCanvas) {
    if (!isLogged) {
      offCanvas = isDesktop;
    } else {
      offCanvas = isOffCanvas;
    }
  } else {
    offCanvas = isDesktop;
  }

  const getMappedMainItems = () => {
    const items = getMainItems(userRoles);

    return items.map((item) => {
      return (
        <ActiveLink
          key={item.name}
          href={item.link}
          isNextLink={item.app === App.Next}
        >
          <>
            {isLogged && <FontAwesomeIcon icon={item.icon} />}
            <span>{item.name} </span>
          </>
        </ActiveLink>
      );
    });
  };

  const getMappedActionItems = () => {
    if (!isLogged) {
      // TODO: Temporary solution to redirect react public pages to next
      return <LoginAndRegisterButtons />;
    }

    const items = getActionItems(userRoles);

    return items.map((item) => {
      return (
        <ActiveLink
          key={item.name}
          href={item.link}
          isNextLink={item.app === App.Next}
        >
          {item.name === "tasks" ? (
            <UnreadTasksCounter />
          ) : (
            <>
              <FontAwesomeIcon icon={item.icon} />
              <span>{item.name}</span>
            </>
          )}
        </ActiveLink>
      );
    });
  };

  const getMappedProfileItems = () => {
    const items = getProfileItems(userRoles);

    if (items.length <= 0) {
      return null;
    }

    return items.map((item) => {
      return (
        <ActiveLink
          key={item.name}
          href={item.link}
          isNextLink={item.app === App.Next}
          onClickLink={() => setShowDropdown(false)}
        >
          <span>{item.name}</span>
        </ActiveLink>
      );
    });
  };

  const getMappedAccountItems = () => {
    const items = getAccountItems(userRoles);

    if (items.length <= 0) {
      return null;
    }

    return items.map((item) => {
      return (
        <ActiveLink
          key={item.name}
          href={item.link}
          isNextLink={item.app === App.Next}
          onClickLink={() => setShowDropdown(false)}
        >
          <span>{item.name}</span>
        </ActiveLink>
      );
    });
  };

  return (
    <CommonHeader
      showDropdown={showDropdown}
      user={keycloak?.tokenParsed}
      isOffCanvas={offCanvas}
      mainMenuItems={getMappedMainItems()}
      actionItems={getMappedActionItems()}
      profileItems={getMappedProfileItems()}
      accountItems={getMappedAccountItems()}
      profileCompletion={profileCompletion}
      onClickDropdown={(value) => setShowDropdown(value)}
      onClickLogout={handleClickLogout}
      onClickChangePassword={handleClickChangePassword}
      onClickProfilePercentage={() => history.push("/profile")}
    />
  );
};

Header.propTypes = {
  userRoles: PropTypes.array,
  isOffCanvas: PropTypes.bool,
};

Header.defaultProps = {
  userRoles: [],
};

export default Header;
