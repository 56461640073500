import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import NavDropdown from "react-bootstrap/NavDropdown";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useKeycloak } from "@react-keycloak/web";
import { faCamera, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Address from "Components/Address";
import CustomSelect from "Components/CustomSelect";
import PhoneNumberInput from "Components/PhoneNumberInput";
import UploadDocumentModal from "Components/UploadDocumentModal";
import UserImage from "Components/UserImage";
import { usePublicRoles } from "Hooks/useRoles";
import BackendService from "Services/BackendService";
import {
  getCountryNameByCode,
  getStateNameByCodeAndCountry,
} from "Services/CountryService";

import "Components/styles/ProfileGeneralInformation.css";
import "Components/styles/Card.css";

function ProfileGeneralInformation(props) {
  const { profile } = props;

  const [isEditing, setIsEditing] = useState(false);
  const [showUploadDocumentModal, setShowUploadDocumentModal] = useState(false);

  const { roles } = usePublicRoles();
  const { keycloak } = useKeycloak();

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    mode: "onChange",
  });

  // Save form hook in constant to pass down as props
  const formProps = { register, errors, control, watch, setValue };

  const { isValid } = formState;

  const handleClose = () => {
    setIsEditing(false);
    reset();
  };

  useEffect(() => {
    if (profile) {
      reset({
        name: profile.name,
        company: profile.company,
        email: profile.email,
        phone: profile.phone,
        country: profile.address?.country && {
          label: getCountryNameByCode(profile.address?.country),
          value: profile.address?.country,
        },
        state: profile.address?.state && {
          value: profile.address?.state,
          label: getStateNameByCodeAndCountry(
            profile.address?.state,
            profile.address?.country
          ),
        },
        city: profile.address?.city,
      });
    }
  }, [profile, reset]);

  // Roles
  const rolesOptions = roles.map((role) => {
    return {
      value: role.name,
      label: role.name,
    };
  });

  const refreshToken = () => {
    keycloak.updateToken(-1);
    window.location.reload();
  };

  const onSubmit = async (formValues) => {
    const updatedProfile = {
      name: formValues.name,
      company: formValues.company,
      email: formValues.email,
      role: formValues.role ?? "",
      callingCode: "",
      phone: formValues.phone,
      address: {
        city: formValues.city ?? "",
        country: formValues.country?.value ?? "",
        state: formValues.state?.value ?? "",
      },
    };

    if (formValues.phone) {
      updatedProfile.callingCode = formValues.callingCode;
    }

    try {
      if (isValid) {
        const data = await BackendService.updateProfile(updatedProfile);

        if (data.name !== profile.name) {
          refreshToken();
        }

        props.onSaved?.(data);
      }
    } catch (error) {
      toast.error("Error, please try again.");
    } finally {
      setIsEditing(false);
    }
  };

  const handleDeleteProfilePicture = async () => {
    try {
      await BackendService.deleteProfilePicture();
      refreshToken();
    } catch (error) {
      toast.error("Error, please try again.");
    }
  };

  const profilePicture = (
    <div className="ContainerProfilePicture">
      <UserImage
        userName={profile.name}
        userImageUrl={keycloak.tokenParsed.imageUrl}
        isProfilePicture
        withoutCache
      />
      <div className="Icon">
        <FontAwesomeIcon icon={faCamera} />
      </div>
    </div>
  );

  return (
    <>
      <UploadDocumentModal
        show={showUploadDocumentModal}
        isFileNameEditable={false}
        allowedExtensions={["png", "jpg", "jpeg"]}
        isProfilePicture={true}
        onSaved={refreshToken}
        onClose={() => setShowUploadDocumentModal(false)}
      />

      <Card className="Card ProfileGI">
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Card.Body>
            <div>
              <p>
                <b>General information</b>
              </p>
              {isEditing ? (
                <>
                  <Form.Group controlId="name" className="form-group required">
                    <Form.Label>Full name</Form.Label>
                    <Form.Control
                      name="name"
                      {...register("name", { required: true })}
                      isInvalid={!!errors.name}
                    />
                    {errors.name && (
                      <Form.Control.Feedback type="invalid">
                        {errors.name.type === "required" &&
                          "Your name is required"}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group controlId="role" className="form-group">
                    <Form.Label>Role</Form.Label>
                    <CustomSelect
                      name="role"
                      control={control}
                      options={rolesOptions}
                      defaultValue={{
                        value: profile.role,
                        label: profile.role,
                      }}
                      placeholder="Start typing to search in roles..."
                    />
                  </Form.Group>

                  <Form.Group controlId="company" className="form-group">
                    <Form.Label>Company</Form.Label>
                    <Form.Control name="company" {...register("company")} />
                  </Form.Group>
                </>
              ) : (
                <Row>
                  <Col
                    xs="auto"
                    onClick={() =>
                      !keycloak.tokenParsed.imageUrl &&
                      setShowUploadDocumentModal(true)
                    }
                  >
                    {keycloak.tokenParsed.imageUrl ? (
                      <NavDropdown
                        className="UserProfile"
                        title={profilePicture}
                      >
                        <NavDropdown.Item
                          href="#"
                          onClick={() => setShowUploadDocumentModal(true)}
                        >
                          Change profile picture
                        </NavDropdown.Item>

                        <NavDropdown.Item
                          href="#"
                          onClick={handleDeleteProfilePicture}
                        >
                          Delete
                        </NavDropdown.Item>
                      </NavDropdown>
                    ) : (
                      profilePicture
                    )}
                  </Col>
                  <Col className="fs-13">
                    <div>
                      <b>Full name: </b>
                      {profile.name}
                    </div>
                    <div>
                      <b>Role: </b>
                      {profile.role}
                    </div>
                    <div>
                      <b>Company: </b>
                      {profile.company}
                    </div>
                  </Col>
                </Row>
              )}
            </div>
            <hr />
            <div>
              <p>
                <b>Contact information</b>
              </p>
              <div className="fs-13">
                <p>
                  Your email address is where we&apos;ll send communication for
                  your hiring process.
                </p>
                {isEditing ? (
                  <>
                    <Form.Group controlId="email" className="form-group">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        name="email"
                        {...register("email")}
                        disabled
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <PhoneNumberInput
                        formProps={formProps}
                        userCallingCode={profile.callingCode}
                      />
                    </Form.Group>
                  </>
                ) : (
                  <>
                    <div>
                      <b>Email: </b>
                      {profile.email}
                    </div>
                    <div>
                      <b>Phone number: </b>
                      {profile?.callingCode} {profile?.phone}
                    </div>
                  </>
                )}
              </div>
            </div>
            <Address
              compact
              isEditing={isEditing}
              address={profile?.address}
              formProps={formProps}
            />
          </Card.Body>

          <Card.Footer>
            {isEditing ? (
              <>
                <Button
                  className="Cancel"
                  variant="secondary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  className="Save"
                  variant="primary"
                  type="submit"
                  disabled={!isValid}
                >
                  Save
                </Button>
              </>
            ) : (
              <Button
                className="Edit"
                variant="link"
                onClick={() => setIsEditing(true)}
              >
                <FontAwesomeIcon icon={faPen} className="Icon" />
                Edit general information
              </Button>
            )}
          </Card.Footer>
        </Form>
      </Card>
    </>
  );
}

ProfileGeneralInformation.propTypes = {
  profile: PropTypes.object.isRequired,
  onSaved: PropTypes.func,
};

export default ProfileGeneralInformation;
