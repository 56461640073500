import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  PrivacyPolicyAndReferralTermsConditionsLabel,
  ReferralCelebrationModal,
} from "@itj-micoach/micoach-common";
import { PublicFileEntityEnum } from "@micoach/js-sdk";
import debounce from "lodash.debounce";
import { FileApi, ReferApi } from "micoach-api";

import DocumentItem from "Components/Common/DocumentItem";
import DragDrop from "Components/Common/DragDrop";
import FormControlErrorMessage from "Components/Common/FormControlErrorMessage";
import CustomSelect from "Components/CustomSelect";
import S3Service from "Services/S3Service";
import { EMAIL_PATTERN } from "constants.js";
import { isEmailFormatValid, mapToSelectOptions } from "utils.js";

import styles from "Components/Refer/styles/ReferralModal.module.css";

const ReferralModal = (props) => {
  const {
    show,
    isPublic,
    jobPositions,
    currentJobPosition,
    allowedExtensions,
    roles,
    onClose,
    onSaved,
  } = props;

  const [fileObject, setFileObject] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [showReferralCelebrationModal, setShowReferralCelebrationModal] =
    useState(false);

  const history = useHistory();
  const {
    register,
    handleSubmit,
    control,
    setError,
    clearErrors,
    trigger,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  // Helpers

  const validateReferralExistence = async (email) => {
    try {
      if (email.length && isEmailFormatValid(email)) {
        await ReferApi.existsReferral(email);

        setError("referralExists.email", {
          type: "validate",
          shouldFocus: true,
        });
      }
    } catch (error) {
      const status = error.response?.status;

      if (status === 404) {
        clearErrors("referralExists.email");
      } else {
        console.error(error);
      }
    } finally {
      trigger("email");
    }
  };

  const debouncedValidateReferralExistence = useRef(
    debounce(validateReferralExistence, 1000)
  ).current;

  // Handlers

  const onSubmit = async (formValues) => {
    if (fileObject) {
      try {
        setUploading(true);

        const refer = {
          ...formValues,
          contentType: fileObject.type,
        };

        const { data } = await FileApi.getPublicUploadFileUrl({
          contentType: refer.contentType,
          entity: PublicFileEntityEnum.Referral,
        });

        await S3Service.uploadFileToS3(data.url, fileObject);
        refer.resumeKey = data.key;

        let response = {};

        if (isPublic) {
          await ReferApi.createPublicReferral(refer);
        } else {
          response = await ReferApi.createReferral(refer);
        }

        if (!isPublic) {
          handleClose();
        }

        setFileObject(null);

        if (onSaved && !isPublic) {
          onSaved(response.data);
        }

        setShowReferralCelebrationModal(true);
      } catch (error) {
        const status = error.response?.status;
        const errorMessage = error.response?.data?.errors?.[0]?.message;

        if (status === 409 && errorMessage) {
          toast.error(errorMessage);
        } else if (status === 404) {
          toast.error("Not found");
        } else {
          toast.error("Error, please try again.");
          console.error(error);
        }
      } finally {
        setUploading(false);
      }
    } else {
      toast.error("Error! You must select or drag a file.");
    }
  };

  const handleDropDocument = (acceptedFiles) => {
    if (acceptedFiles?.length) {
      setFileObject(acceptedFiles[0]);
    }
  };

  const handleChangeReferralEmail = async (e) => {
    clearErrors("referralExists.email");
    await debouncedValidateReferralExistence(e.target.value);
  };

  const handleClose = () => {
    setFileObject(null);
    onClose?.();
  };

  const handleNavigateToMyReferrals = () => {
    history.push("/my-referrals");

    setShowReferralCelebrationModal(false);
  };

  useEffect(() => {
    reset();
  }, [show, reset]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        className={styles.ReferralModal}
      >
        <Form onSubmit={handleSubmit((formValues) => onSubmit(formValues), {})}>
          <Modal.Header closeButton>
            <Modal.Title>Refer a friend</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={styles.Description}>
              If you know someone that could be a great fit for our open Job
              Positions, please fill the required information. The ITJ TA Team
              will contact your friend and ask about their skills.
              {isPublic && " We recommend you to create a miCoach account."}
            </div>
            {isPublic && (
              <>
                <Form.Group
                  controlId="referrerName"
                  className="form-group required"
                >
                  <Form.Label id="referrerName">Your name</Form.Label>
                  <Form.Control
                    type="text"
                    aria-labelledby="referrerName"
                    isInvalid={!!errors.referrerName}
                    disabled={uploading}
                    {...register("referrerName", { required: true })}
                  />
                  <FormControlErrorMessage
                    show={errors.referrerName?.type === "required"}
                    message="Your name is required"
                  />
                </Form.Group>
                <Form.Group
                  controlId="referrerEmail"
                  className="form-group required"
                >
                  <Form.Label>Your email</Form.Label>
                  <Form.Control
                    type="text"
                    isInvalid={!!errors.referrerEmail}
                    disabled={!isPublic || uploading}
                    {...register("referrerEmail", {
                      pattern: EMAIL_PATTERN,
                      required: true,
                    })}
                  />
                  <FormControlErrorMessage
                    show={errors.referrerEmail?.type === "required"}
                    message="Your email is required"
                  />
                  <FormControlErrorMessage
                    show={errors.referrerEmail?.type === "pattern"}
                    message="Your email must be a valid email"
                  />
                </Form.Group>
              </>
            )}

            <Form.Group controlId="name" className="form-group required">
              <Form.Label>Referral name</Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.name}
                disabled={uploading}
                {...register("name", {
                  required: true,
                })}
              />
              <FormControlErrorMessage
                show={errors.name?.type === "required"}
                message="Referral name is required"
              />
            </Form.Group>
            <Form.Group controlId="email" className="form-group required">
              <Form.Label>Referral email</Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.email || errors.referralExists?.email}
                disabled={uploading}
                {...register("email", {
                  pattern: EMAIL_PATTERN,
                  required: true,
                  validate: () => !errors.referralExists?.email,
                })}
                onChange={handleChangeReferralEmail}
              />
              <FormControlErrorMessage
                show={errors.email?.type === "required"}
                message="Referral email is required"
              />
              <FormControlErrorMessage
                show={errors.email?.type === "pattern"}
                message="Referral email must be a valid email"
              />
              <FormControlErrorMessage
                show={
                  errors.referralExists?.email ||
                  errors.email?.type === "validate"
                }
                message="The referral already exists."
              />
            </Form.Group>
            <Form.Group controlId="role" className="form-group">
              <Form.Label id="role">Referral role</Form.Label>
              <CustomSelect
                name="role"
                control={control}
                isInvalid={!!errors.role}
                options={mapToSelectOptions(roles, "name", "name")}
                disabled={uploading}
              />
            </Form.Group>
            <Form.Group controlId="position" className="form-group">
              <Form.Label id="position">Open Job Position</Form.Label>
              <CustomSelect
                name="position"
                control={control}
                isInvalid={!!errors.position}
                options={mapToSelectOptions(jobPositions, "_id", "title")}
                defaultValue={currentJobPosition}
                disabled={uploading}
              />
            </Form.Group>
            <Form.Group controlId="comments" className="form-group">
              <Form.Label>
                Describe why you are referring your friend
              </Form.Label>
              <Form.Control
                as="textarea"
                type="text"
                control={control}
                disabled={uploading}
                className="h-100"
                {...register("comments")}
              />
            </Form.Group>
            <Form.Group controlId="resume" className="form-group required">
              <Form.Label id="resume">Upload referral resume</Form.Label>
              {!fileObject && (
                <DragDrop
                  allowedExtensions={allowedExtensions}
                  onDrop={handleDropDocument}
                />
              )}

              {fileObject && (
                <DocumentItem
                  document={fileObject}
                  isSaving={uploading}
                  onDelete={() => setFileObject(null)}
                />
              )}
            </Form.Group>
            <PrivacyPolicyAndReferralTermsConditionsLabel
              leadText="By referring, you agree to our "
              showReferralTerms
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={handleClose}
              disabled={uploading}
            >
              Close
            </Button>
            <Button
              type="button"
              onClick={handleSubmit((formValues) => onSubmit(formValues))}
              disabled={uploading}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <ReferralCelebrationModal
        show={showReferralCelebrationModal}
        isAuthenticated
        onClose={() => setShowReferralCelebrationModal(false)}
        onNavigateToMyReferrals={handleNavigateToMyReferrals}
      />
    </>
  );
};

ReferralModal.propTypes = {
  show: PropTypes.bool.isRequired,
  isPublic: PropTypes.bool,
  currentJobPosition: PropTypes.shape({
    _id: PropTypes.string,
    title: PropTypes.string,
  }),
  allowedExtensions: PropTypes.arrayOf(PropTypes.string),
  roles: PropTypes.array,
  jobPositions: PropTypes.array,
  onClose: PropTypes.func,
  onSaved: PropTypes.func,
};

ReferralModal.defaultProps = {
  show: false,
  isPublic: true,
  allowedExtensions: ["pdf", "doc", "docx"],
  roles: [],
  jobs: [],
};

export default ReferralModal;
